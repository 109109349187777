import About from './About';
import Community from './Community';
import CommunityRules from './CommunityRules';
import Contact from './Contact';
import Event from './Event';
import Events from './Events';
import FAQ from './FAQ';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import getSharedLoader from '../lib/router/getSharedLoader';
import {linkToApp} from '../lib/url/linkToApp';

import React from 'react';
import {Route, redirect} from 'react-router-dom';

export default (
  <>
    <Route
      element={<Event />}
      loader={getSharedLoader({
        loadableRoot: Event,
      })}
      path="/event/:id"
    />
    <Route
      element={<Events />}
      loader={getSharedLoader({
        loadableRoot: Events,
      })}
      path="/event"
    ></Route>
    <Route
      element={<About />}
      loader={getSharedLoader({
        loadableRoot: About,
      })}
      path="/about"
    />
    <Route
      element={<Community />}
      loader={getSharedLoader({
        loadableRoot: Community,
      })}
      path="/community"
    />
    <Route
      element={<CommunityRules />}
      loader={getSharedLoader({
        loadableRoot: CommunityRules,
      })}
      path="/community-rules"
    />
    <Route
      element={<FAQ />}
      loader={getSharedLoader({
        loadableRoot: FAQ,
      })}
      path="/faq"
    />
    <Route
      element={<Contact />}
      loader={getSharedLoader({
        loadableRoot: Contact,
      })}
      path="/contact"
    />
    <Route
      element={<PrivacyPolicy />}
      loader={getSharedLoader({
        loadableRoot: PrivacyPolicy,
      })}
      path="/privacy-policy"
    />
    <Route
      element={<TermsOfUse />}
      loader={getSharedLoader({
        loadableRoot: TermsOfUse,
      })}
      path="/terms-of-use"
    />

    <Route
      loader={() => redirect(linkToApp('/login'))}
      path="/login"
    />
    <Route
      loader={() => redirect(linkToApp('/signup'))}
      path="/signup"
    />
    <Route
      loader={() => redirect(linkToApp('/reset-password'))}
      path="/reset-password"
    />
  </>
);
