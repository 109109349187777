import CSRAppRoot from '../lib/components/PageRoot/CSRAppRoot';
import loadLocale from '../lib/locale/loadLocale';
import createDefaultBrowserLogger from '../lib/logger/createDefaultBrowserLogger';
import localeCookieWithSmartFallback from '../server/lib/localeCookieWithSmartFallback';

import {loadableReady} from '@loadable/component';
import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import intl from 'react-intl-universal';

const LOGGER = createDefaultBrowserLogger();

async function init(): Promise<void> {
  const locale = localeCookieWithSmartFallback();
  const localesData = await loadLocale(locale, LOGGER);
  await intl.init(localesData);
  ReactDOM.hydrateRoot(
    document.getElementById('root') as Element,
    <StrictMode>
      <CSRAppRoot />
    </StrictMode>,
  );
}

void loadableReady(init);
