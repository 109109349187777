import Google from './Google';
import getSharedLoader from '../../../lib/router/getSharedLoader';

import React from 'react';
import {Outlet, Route} from 'react-router-dom';

export default (
  <>
    <Route
      element={<Outlet />}
      path="oauth/*"
    >
      <Route
        element={<Google />}
        loader={getSharedLoader({
          loadableRoot: Google,
        })}
        path="google"
      />
    </Route>
  </>
);
