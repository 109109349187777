import SetupPublicProfile from '.';
import LoadableBiographyStep from './BiographyStep/LoadableBiographyStep';
import LoadableDoneStep from './DoneStep/LoadableDoneStep';
import LoadableEducationStep from './ExperienceStep/LoadableEducationStep';
import LoadableExperienceStep from './ExperienceStep/LoadableExperienceStep';
import LoadableProfessionStep from './ExperienceStep/LoadableProfessionStep';
import LoadableGoalStep from './GoalStep/LoadableGoalStep';
import LoadableNameStep from './NameStep/LoadableNameStep';
import next from '../../__generated__/intl/strings/next';
import readyNextLabel from '../../__generated__/intl/strings/pages/setupPublicProfile/readyNextLabel';
import getSharedLoader from '../../lib/router/getSharedLoader';
import {linkToApp} from '../../lib/url/linkToApp';

import React from 'react';
import {Route, redirect} from 'react-router-dom';

export default (
  <>
    <Route
      element={<SetupPublicProfile />}
      loader={getSharedLoader({
        loadableRoot: SetupPublicProfile,
      })}
      path="profile/wizard/*"
    >
      <Route
        element={
          <LoadableNameStep
            nextStep={{
              getLabel: next,
              to: linkToApp('/profile/wizard/experience'),
            }}
          />
        }
        index={true}
        loader={getSharedLoader({
          loadableRoot: LoadableNameStep,
        })}
      />

      {/* Begin experience routes */}
      <Route
        element={
          <LoadableEducationStep
            next={{
              getLabel: next,
              to: linkToApp('/profile/wizard/biography'),
            }}
          />
        }
        loader={getSharedLoader({
          loadableRoot: LoadableEducationStep,
        })}
        path="experience/education"
      />
      <Route
        element={
          <LoadableProfessionStep
            next={{
              to: linkToApp('/profile/wizard/biography'),
              getLabel: next,
            }}
          />
        }
        loader={getSharedLoader({
          loadableRoot: LoadableProfessionStep,
        })}
        path="experience/profession"
      />
      <Route
        element={
          <LoadableExperienceStep previousUrl={linkToApp('/profile/wizard')} />
        }
        loader={getSharedLoader({
          loadableRoot: LoadableExperienceStep,
        })}
        path="experience"
      />
      {/* End experience routes */}

      <Route
        element={
          <LoadableBiographyStep
            nextStep={{
              getLabel: next,
              to: linkToApp('/profile/wizard/goal'),
            }}
            previousUrl={linkToApp('/profile/wizard/experience')}
          />
        }
        loader={getSharedLoader({
          loadableRoot: LoadableBiographyStep,
        })}
        path="biography"
      />

      <Route
        element={
          <LoadableGoalStep
            nextStep={{
              getLabel: readyNextLabel,
              to: linkToApp('/profile/wizard-done'),
            }}
            previousUrl={linkToApp('/profile/wizard/biography')}
          />
        }
        loader={getSharedLoader({
          loadableRoot: LoadableGoalStep,
        })}
        path="goal"
      />

      <Route
        loader={() => {
          return redirect(linkToApp('/profile/wizard'));
        }}
        path="*"
      />
    </Route>

    <Route
      element={<LoadableDoneStep />}
      loader={getSharedLoader({
        loadableRoot: LoadableDoneStep,
      })}
      path="profile/wizard-done"
    />
  </>
);
