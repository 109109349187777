export type LocaleData =
  typeof import('../../__generated__/translations/en-US.json');
export type LocaleModule = {default: LocaleData};
export type LocalePromise = Promise<LocaleModule>;
export type LocaleLoader = () => LocalePromise;
export type SupportedLocalesMap = Readonly<{
  'en-US': LocaleLoader;
  ja: LocaleLoader;
  'ja-JP': LocaleLoader;
  'zh-CN': LocaleLoader;
}>;

const JAJPLoader = () => import('../../__generated__/translations/ja-JP.json');
export const DEFAULT_LOCALE = 'ja';
export default Object.freeze({
  'en-US': () => import('../../__generated__/translations/en-US.json'),
  'ja-JP': JAJPLoader,
  ja: JAJPLoader,
  'zh-CN': () => import('../../__generated__/translations/zh-CN.json'),
}) as SupportedLocalesMap;
