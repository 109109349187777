import {Logger} from '../../logger/logger';

import {InMemoryCache, NormalizedCacheObject} from '@apollo/client';

export default function restoreOrCreateCache(
  initialQueryState: unknown,
  logger: Logger,
): InMemoryCache {
  let cache = new InMemoryCache({
    typePolicies: {
      WeeklyTimetable: {
        fields: {
          tasks: {
            merge(_existing, incoming) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return incoming;
            },
          },
        },
      },
    },
  });

  try {
    if (typeof initialQueryState === 'object' && initialQueryState != null) {
      cache = cache.restore(initialQueryState as NormalizedCacheObject);

      // Move this to the component that extracts the initial data from window.
      // window.INITIAL_QUERY_STATE = null;
      // const scriptNode = document.getElementById('initial-state');
      // if (scriptNode != null) {
      //   scriptNode.remove();
      // }
    }
  } catch (err) {
    logger.logError(err);
  }
  return cache;
}
