import {SupportedLocalesMap} from '../../locale/SupportedLocales';

import React from 'react';

export type AppContextValue = Readonly<{
  graphqlUrl: string;
  initialQueryState: object | null;
  locale: keyof SupportedLocalesMap;
  statusCode?: number;
}>;

export default React.createContext<AppContextValue>({
  graphqlUrl: '',
  initialQueryState: null,
  locale: 'ja',
});
