import FormatText from './FormatText';
import {BaseTextProps, FontFamily} from './options';
import useSharedHTMLTextProps from './useSharedHTMLTextProps';
import useSharedTextCSS from './useSharedTextCSS';
import {CSSStyles} from '../../../types/CSSStyles';

import {css} from '@emotion/react';
import React from 'react';

export type TitleTextSize =
  | 'XXL'
  | 'XL'
  | 'L'
  | 'M'
  | 'S'
  | 'XS'
  | 'XXS'
  | 'XXXS';

export interface Props extends BaseTextProps {
  readonly children: React.ReactNode;
  readonly size: TitleTextSize;
}

export default function TitleText(props: Props): JSX.Element {
  const {children, size} = props;
  const htmlProps = useSharedHTMLTextProps(props);
  const sharedCSS = useSharedTextCSS(props);
  let Component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  let style: CSSStyles;
  switch (size) {
    case 'XXL':
      Component = 'h1';
      style = css({
        fontSize: 44,
        lineHeight: '1.45em',
      });
      break;
    case 'XL':
      Component = 'h2';
      style = css({
        fontSize: 32,
        lineHeight: '1.5em',
      });
      break;
    case 'L':
      Component = 'h3';
      style = css({
        fontSize: 24,
        lineHeight: '1.5em',
      });
      break;
    case 'M':
      Component = 'h4';
      style = css({
        fontSize: 22,
        lineHeight: '1.5em',
      });
      break;
    case 'S':
      Component = 'h5';
      style = css({
        fontSize: 18,
        lineHeight: '1.4em',
      });
      break;
    case 'XS':
      Component = 'h6';
      style = css({
        fontSize: 16,
        lineHeight: '1.4em',
      });
      break;
    case 'XXS':
      Component = 'h6';
      style = css({
        fontSize: 13,
        lineHeight: '1.4em',
      });
      break;
    case 'XXXS':
      Component = 'h6';
      style = css({
        fontSize: 11,
        lineHeight: '1.4em',
      });
  }

  return (
    <Component
      css={[
        sharedCSS,
        css({
          fontFamily: FontFamily.NOTO_SANS_JS,
          fontWeight: 700,
        }),
        style,
      ]}
      {...htmlProps}
    >
      <FormatText>{children}</FormatText>
    </Component>
  );
}
