import type {LocaleData} from './SupportedLocales';

import SupportedLocales from './SupportedLocales';
import {Logger} from '../logger/logger';

export type LocaleResult = Readonly<{
  currentLocale: string;
  locales: {[locale: string]: LocaleData};
}>;

let localeResults: Record<string, LocaleResult> = {};

export function resetLocaleResults(): void {
  localeResults = {};
}

export async function preloadLocales(): Promise<void> {
  const promises = Object.entries(SupportedLocales).map(([_, promise]) => {
    return promise();
  });
  await Promise.all(promises);
}

export default async function loadLocale(
  locale: string,
  _: Logger,
): Promise<LocaleResult> {
  const loadedLocale = localeResults[locale];
  if (loadedLocale == null) {
    let resultLocale: keyof typeof SupportedLocales;
    let promise;
    switch (locale) {
      case 'zh-CN':
        resultLocale = 'zh-CN';
        promise = SupportedLocales[resultLocale];
        break;
      case 'en-US':
        resultLocale = 'en-US';
        promise = SupportedLocales[resultLocale];
        break;
      case 'ja':
      case 'ja-JP':
      default:
        resultLocale = 'ja-JP';
        promise = SupportedLocales[resultLocale];
    }
    const result = {
      currentLocale: resultLocale,
      locales: {[resultLocale]: (await promise()).default},
    };
    localeResults[resultLocale] = result;
    return result;
  }
  return loadedLocale;
}
