import type {Theme as EmotionTheme} from '@emotion/react';

import {Breakpoints, defaultBreakpoints} from './breakpoints';
import {Palettes, defaultPalettes} from './palettes';
import {Sizes, defaultSizes} from './sizes';
import {Spacing, defaultSpacing} from './spacing';

export interface Theme extends EmotionTheme {
  readonly breakpoints: Breakpoints,
  readonly fontFamily: string;
  readonly palettes: Palettes;
  readonly sizes: Sizes;
  readonly spacing: Spacing;
};

export const defaultTheme: Theme = {
  breakpoints: defaultBreakpoints,
  fontFamily: 'Noto Sans JP',
  palettes: defaultPalettes,
  sizes: defaultSizes,
  spacing: defaultSpacing,
};

