import {CSSStyles} from '../../../types/CSSStyles';

import {css} from '@emotion/react';
import React, {AriaRole} from 'react';

type OmitKeys = 'style';
export interface Props
  extends Omit<React.HTMLAttributes<HTMLDivElement>, OmitKeys> {
  readonly children: React.ReactNode;
  readonly 'data-testid'?: string;
  readonly direction?: 'row' | 'column';
  readonly role?: AriaRole;
  readonly sx?: CSSStyles;
}

export default function Flex(props: Props) {
  const {
    children,
    'data-testid': testID,
    direction = 'row',
    sx,
    ...others
  } = props;

  return (
    <div
      css={[
        css({
          alignItems: 'center',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: direction,
        }),
        sx,
      ]}
      data-testid={testID}
      {...others}
    >
      {children}
    </div>
  );
}
