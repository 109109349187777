import {AppContextValue} from './AppContext';

export function isAppContextValue(value: unknown): value is AppContextValue {
  if (value == null) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  if (!('graphqlUrl' in value) || typeof value.graphqlUrl !== 'string') {
    return false;
  }

  if (
    !('initialQueryState' in value) ||
    typeof value.initialQueryState !== 'object'
  ) {
    return false;
  }

  if ('statusCode' in value && typeof value.statusCode !== 'number') {
    return false;
  }

  if (!('locale' in value) || typeof value.locale !== 'string') {
    return false;
  }

  return true;
}
