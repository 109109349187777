import Calendar from './Calendar';
import CalendarRoutes from './Calendar/CalendarRoutes';
import Discover from './Discover';
import Member from './Member';
import MentorshipRoutes from './Mentorship/MentorshipRoutes';
import OAuthRoutes from './OAuth/OAuthRoutes';
import MyHome from './MyHome';
import MyPageEvent from './MyPageEvent';
import MyProfile from './MyProfile';
import getSharedLoader from '../../lib/router/getSharedLoader';
import NotFoundPageContent from '../NotFound/NotFoundPageContent';
import SetupPublicProfileRoutes from '../SetupPublicProfile/SetupPublicProfileRoutes';

import React from 'react';
import {Route} from 'react-router-dom';

export default (
  <>
    <Route
      element={<MyHome />}
      index={true}
      key="home"
      loader={getSharedLoader({
        loadableRoot: MyHome,
      })}
    />
    ,
    <Route
      element={<MyPageEvent />}
      key="event"
      loader={getSharedLoader({
        loadableRoot: MyPageEvent,
      })}
      path="event/:id"
    />
    ,
    <Route
      element={<Discover />}
      key="discover"
      loader={getSharedLoader({
        loadableRoot: Discover,
      })}
      path="discover"
    />
    ,
    <Route
      element={<Member />}
      key="member"
      loader={getSharedLoader({
        loadableRoot: Member,
      })}
      path="member/:id"
    />
    ,
    <Route
      element={<MyProfile />}
      key="my-profile"
      loader={getSharedLoader({
        loadableRoot: MyProfile,
      })}
      path="my-profile"
    />
    {SetupPublicProfileRoutes}
    {MentorshipRoutes}
    {CalendarRoutes}
    {OAuthRoutes}
    <Route
      element={<Calendar />}
      loader={getSharedLoader({
        loadableRoot: Calendar,
      })}
      path="calendar"
    />
    <Route
      element={<NotFoundPageContent />}
      path="*"
    />
  </>
);
