import {BaseTextProps} from './options';
import useContentPalette from '../theme/useContentPalette';

import {ArrayInterpolation, Theme, css} from '@emotion/react';

export default function useSharedTextCSS(
  props: BaseTextProps,
): ArrayInterpolation<Theme> {
  const {color, display, isDisabled, sx: rootStyle} = props;
  const palette = useContentPalette(color);
  const colorStyle = isDisabled ? palette.disabled : palette.default;
  return [
    css({
      display,
      margin: 0,
      color: colorStyle,
      wordBreak: 'normal',
    }),
    rootStyle,
  ];
}
