// import supportedLocales, {DEFAULT_LOCALE} from '../../lib/locale/SupportedLocales';
// import {getUserLocales} from 'get-user-locale';
// import Cookies from 'js-cookie';

export const LOCALE_COOKIE_KEY = 'locale';

// function guessUserLocale(): string {
//   const userLocales = getUserLocales();
//   for (const locale of userLocales) {
//     if (locale in supportedLocales) {
//       return locale;
//     }
//   }
//   return DEFAULT_LOCALE;
// }

export default function localeCookieWithSmartFallback(): string {
  // const cookieValue = Cookies.get(LOCALE_COOKIE_KEY);
  // if (typeof cookieValue === 'string' && cookieValue in supportedLocales) {
  //   return cookieValue;
  // }
  // return guessUserLocale();

  // TODO revert back to dynamic locale after translations.
  return 'ja';
}
