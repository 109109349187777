import brown from './colors/brown';
import pink from './colors/pink';
import white from './colors/white';

export interface Palette {
  readonly active: string;
  readonly default: string;
  readonly disabled: string;
  readonly focus: string;
  readonly hover: string;
}

export interface ContentPalettes {
  readonly accent: Palette;
  readonly disabled: Palette;
  readonly negative: Palette;
  readonly neutral: Palette;
  readonly neutralContrast: Palette;
  readonly neutralSubdued: Palette;
  readonly positive: Palette;
  readonly subAccent1: Palette;
  readonly subAccent2: Palette;
}

export interface BackgroundPalettes {
  readonly accent: Palette;
  readonly contrast: Omit<BackgroundPalettes, 'contrast'>;
  readonly neutral: Palette;
  // Can be used as a background on a neutral background
  readonly neutralContrast: Palette;
  readonly neutralExtraSubdued: Palette;
  readonly neutralSubdued: Palette;
  readonly subAccent1: Palette;
  readonly subAccent2: Palette;
  readonly white: Palette;
}

export interface Palettes {
  readonly background: BackgroundPalettes;
  readonly content: ContentPalettes;
}

export const defaultPalettes: Palettes = {
  background: {
    accent: {
      active: pink.pink1000,
      default: pink.pink800,
      disabled: brown.brown200,
      focus: pink.pink900,
      hover: pink.pink900,
    },
    neutral: {
      active: brown.brown1000,
      default: brown.brown800,
      disabled: brown.brown200,
      focus: brown.brown900,
      hover: brown.brown900,
    },
    neutralContrast: {
      active: brown.brown900,
      default: brown.brown700,
      disabled: brown.brown200,
      focus: brown.brown800,
      hover: brown.brown800,
    },
    neutralExtraSubdued: {
      active: brown.brown100,
      default: brown.brown100,
      disabled: brown.brown100,
      focus: brown.brown100,
      hover: brown.brown300,
    },
    neutralSubdued: {
      active: brown.brown500,
      default: brown.brown300,
      disabled: brown.brown200,
      focus: brown.brown400,
      hover: brown.brown400,
    },
    subAccent1: createPurePalette('#4EBFB4'),
    subAccent2: createPurePalette('#FFB24F'),
    white: {
      active: brown.brown300,
      default: white.solid,
      disabled: brown.brown100,
      focus: brown.brown200,
      hover: brown.brown200,
    },

    contrast: {
      accent: {
        active: '#FFFFFF',
        default: '#FFFFFF',
        disabled: '#C7BBC0',
        focus: '#FFFFFF',
        hover: '#FFFFFF',
      },
      neutral: {
        active: '#FFFFFF',
        default: '#FFFFFF',
        disabled: '#C7BBC0',
        focus: '#FFFFFF',
        hover: '#FFFFFF',
      },
      neutralContrast: {
        active: '#FFFFFF',
        default: '#FFFFFF',
        disabled: '#C7BBC0',
        focus: '#FFFFFF',
        hover: '#FFFFFF',
      },
      neutralSubdued: {
        ...createPurePalette(brown.brown900),
        disabled: '#C7BBC0',
      },
      neutralExtraSubdued: {
        ...createPurePalette(brown.brown900),
        disabled: '#C7BBC0',
      },
      subAccent1: createPurePalette('#4EBFB4'),
      subAccent2: createPurePalette('#FFB24F'),
      white: createPurePalette(brown.brown900),
    },
  },
  content: {
    accent: {
      active: '#DA1968',
      default: '#FF3A8B',
      disabled: '#C7BBC0',
      focus: '#EC2979',
      hover: '#EC2979',
    },
    disabled: createPurePalette(brown.brown200),
    negative: {
      active: '#C1120D',
      default: '#D31510',
      disabled: '#C7BBC0',
      focus: '#CB140F',
      hover: '#CB140F',
    },
    neutral: {
      active: '#380C1D',
      default: '#431527',
      disabled: '#C7BBC0',
      focus: '#380C1D',
      hover: '#380C1D',
    },
    neutralContrast: {
      active: '#FFFFFF',
      default: '#FFFFFF',
      disabled: '#FFFFFF',
      focus: '#FFFFFF',
      hover: '#FFFFFF',
    },
    neutralSubdued: {
      active: brown.brown700,
      default: brown.brown600,
      disabled: brown.brown400,
      focus: brown.brown700,
      hover: brown.brown700,
    },
    positive: createPurePalette('#008F5D'),
    subAccent1: createPurePalette('#4EBFB4'),
    subAccent2: createPurePalette('#FFB24F'),
  },
};

export function createPurePalette(color: string): Palette {
  return {
    active: color,
    default: color,
    disabled: color,
    focus: color,
    hover: color,
  };
}
