type TypeChecker<T> = (value: unknown) => value is T;

export class TypeCheckedOptional<T> {
  constructor(
    private value: unknown,
    private checkType: TypeChecker<T>,
  ) {}

  get(): T | null {
    if (this.checkType(this.value)) {
      return this.value;
    }

    return null;
  }

  orElse(fallback: T): T {
    return this.get() ?? fallback;
  }
}

export default class Optional {
  static of(value: unknown): Optional {
    return new Optional(value);
  }

  constructor(private value: unknown) {}

  checkType<T>(checker: TypeChecker<T>): TypeCheckedOptional<T> {
    return new TypeCheckedOptional<T>(this.value, checker);
  }
}
