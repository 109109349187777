import AuthApolloLink from './AuthApolloLink';
import restoreOrCreateCache from './restoreOrCreateCache';
import {Logger} from '../../logger/logger';

import {
  ApolloClient,
  NormalizedCacheObject,
  createHttpLink,
  from,
} from '@apollo/client';
import fetch from 'cross-fetch';

export default function createAuthApolloClient(
  graphqlUri: string,
  initialQueryState: unknown,
  logger: Logger,
): ApolloClient<NormalizedCacheObject> {
  const authLink = new AuthApolloLink();
  const httpLink = createHttpLink({
    fetch,
    uri: graphqlUri,
    credentials: 'same-origin',
  });
  return new ApolloClient({
    cache: restoreOrCreateCache(initialQueryState, logger),
    link: from([authLink, httpLink]),
  });
}
