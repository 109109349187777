const pink = {
  pink100: '#FFF1F7',
  pink200: '#FFE3EF',
  pink300: '#FFD4E5',
  pink400: '#FFC4DC',
  pink500: '#FF9CC5',
  pink600: '#FF75AE',
  pink700: '#FF589C',
  pink800: '#FF3A8B',
  pink900: '#EC2979',
  pink1000: '#DA1968',
};

export default pink;
