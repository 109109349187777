import React from 'react';

export interface Props {
  readonly children: React.ReactNode;
}
export default function FormatText(props: Props): JSX.Element {
  const {children} = props;
  const elements = React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      const splitByBr = child.split('<br>');

      return (
        <>
          {splitByBr.map((part, i) => {
            let newLine = null;
            if (i < splitByBr.length - 1) {
              newLine = <br />;
            }
            return (
              <span key={i}>
                {part}
                {newLine}
              </span>
            );
          })}
        </>
      );
    }
    return child;
  });

  return <>{elements}</>;
}
