import useAppTheme from './useAppTheme';

import {useCallback} from 'react';

export enum MediaQuery {
  ExtraSmall = 'extra-small',
  SmallScreen = 'small',
  MediumScreen = 'medium',
  LargeScreen = 'large',
  ExtraLargeScreen = 'extra-large',
  SmallAndMedium = 'small-and-medium',
  LargeAndExtraLarge = 'large-and-extra-large',
  NonSmallScreen = 'non-small-screen',
  NonExtraLargeScreen = 'non-extra-large',
  MediumAndLarge = 'medium-large',
}

export type Getter = (query: MediaQuery) => string;

export default function useMediaQueryGetter(): Getter {
  const {breakpoints} = useAppTheme();

  return useCallback(
    (query: MediaQuery) => {
      switch (query) {
        case MediaQuery.ExtraSmall:
          return `@media (max-width: ${breakpoints.sm}px)`;
        case MediaQuery.SmallScreen:
          return `@media (max-width: ${breakpoints.md}px)`;
        case MediaQuery.MediumScreen:
          return `@media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.l}px)`;
        case MediaQuery.LargeScreen:
          return `@media (min-width: ${breakpoints.l}px) and (max-width: ${breakpoints.xl}px)`;
        case MediaQuery.ExtraLargeScreen:
          return `@media (min-width: ${breakpoints.xl}px)`;
        case MediaQuery.SmallAndMedium:
          return `@media (max-width: ${breakpoints.l}px)`;
        case MediaQuery.LargeAndExtraLarge:
          return `@media (min-width: ${breakpoints.l}px)`;
        case MediaQuery.NonExtraLargeScreen:
          return `@media (max-width: ${breakpoints.xl}px)`;
        case MediaQuery.MediumAndLarge:
          return `@media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.xl}px)`;
        case MediaQuery.NonSmallScreen:
          return `@media (min-width: ${breakpoints.sm}px)`;
      }
    },
    [breakpoints.l, breakpoints.md, breakpoints.sm, breakpoints.xl],
  );
}
