import Calendar from '.';
import LinkedCalendars from './LinkedCalendars';
import Timeslots from './Timeslots';
import getSharedLoader from '../../../lib/router/getSharedLoader';

import React from 'react';
import {Route} from 'react-router-dom';

export default (
  <Route
    element={<Calendar />}
    loader={getSharedLoader({
      loadableRoot: Calendar,
    })}
    path="calendar/*"
  >
    <Route
      element={<LinkedCalendars />}
      loader={getSharedLoader({
        loadableRoot: LinkedCalendars,
      })}
      path="connections"
    />

    <Route
      element={<Timeslots />}
      index={true}
      loader={getSharedLoader({
        loadableRoot: Timeslots,
      })}
    />
  </Route>
);
