import {CSSStyles} from '../../../types/CSSStyles';
import {ContentColor} from '../theme/useContentPalette';

export enum FontFamily {
  GARAMOND = '"adobe-garamond-pro", serif',
  NOTO_SANS_JS = '"Noto Sans JP", serif',
}

export interface BaseTextProps {
  readonly color?: ContentColor;
  readonly display?: 'block' | 'inline' | 'inline-block' | 'flex';
  readonly id?: string;
  readonly isDisabled?: boolean;
  readonly sx?: CSSStyles;
}
