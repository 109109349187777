import useAppTheme from './hooks/useAppTheme';
import useMediaQuery from './hooks/useMediaQuery';
import {MediaQuery} from './hooks/useMediaQueryGetter';

import {Global} from '@emotion/react';
import React from 'react';

export default function GlobalStyles(): JSX.Element {
  const theme = useAppTheme();
  const {sizes} = theme;
  return (
    <Global
      styles={[
        {
          '*': {
            '&:focus-visible': {
              outline: `1.5px solid ${theme.palettes.background.accent.focus}`,
              outlineOffset: 2,
            },

            scrollMarginTop: sizes.l.topNavHeight,

            [useMediaQuery(MediaQuery.SmallScreen)]: {
              scrollMarginTop: sizes.sm.topNavHeight,
            },
            [useMediaQuery(MediaQuery.MediumScreen)]: {
              scrollMarginTop: sizes.md.topNavHeight,
            },
          },

          body: {
            color: theme.palettes.content.neutral.default,
            fontFamily: '"Noto Sans JP", serif',
            fontSize: 12,
            fontWeight: 400,
            overflowX: 'hidden',
            overscrollBehavior: 'none',
          },
        },
      ]}
    />
  );
}
