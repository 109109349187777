import ApolloClientProvider from './ApolloClientProvider';
import AppContext from './AppContext';
import EmotionCacheProvider from './EmotionCacheProvider';
import {isAppContextValue} from './isAppContextValue';
import Routes from '../../../pages/Routes';
import AppThemeProvider from '../design_system/AppThemeProvider';
import {WindowScrollDisablerProvider} from '../layout/useWindowScrollDisabler';

import React from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

const router = createBrowserRouter(Routes);

export default function CSRAppRoot(): JSX.Element {
  const initialContextFromWindow = window.INITIAL_APP_CONTEXT;

  if (!isAppContextValue(initialContextFromWindow)) {
    // TODO determine what to do when initial context is missing.
    return <div>Error</div>;
  }

  return (
    <WindowScrollDisablerProvider>
      <EmotionCacheProvider>
        <AppThemeProvider>
          <AppContext.Provider value={initialContextFromWindow}>
            <ApolloClientProvider>
              <RouterProvider router={router} />
            </ApolloClientProvider>
          </AppContext.Provider>
        </AppThemeProvider>
      </EmotionCacheProvider>
    </WindowScrollDisablerProvider>
  );
}
