export const defaultSpacing = {
  x4: '4px',
  x6: '6px',
  x8: '8px',
  x12: '12px',
  x16: '16px',
  x20: '20px',
  x24: '24px',
  x28: '28px',
  x32: '32px',
  x40: '40px',
  x42: '42px',
  x48: '48px',
  x60: '60px',
  x80: '80px',
  gridColumnGap: '16px',
  gridRowGap: '60px',
};

export type Spacing = typeof defaultSpacing;
