import {Palette} from './palettes';
import useAppTheme from '../hooks/useAppTheme';

export enum ContentColor {
  DISABLED = 'disabled',
  ERROR = 'error',
  PRIMARY = 'primary',
  PRIMARY_CONTRAST = 'primary-contrast',
  PRIMARY_SUBDUED = 'primary-subdued',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  // These are situational colors that designers may decide to throw here and there.
  AUXILIARY1 = 'auxiliary1',
  AUXILIARY2 = 'auxiliary2',
}

export default function useContentPalette(color: Maybe<ContentColor>): Palette {
  const {palettes} = useAppTheme();

  switch (color) {
    case ContentColor.AUXILIARY1:
      return palettes.content.subAccent1;
    case ContentColor.AUXILIARY2:
      return palettes.content.subAccent2;
    case ContentColor.DISABLED:
      return palettes.content.disabled;
    case ContentColor.ERROR:
      return palettes.content.negative;
    case ContentColor.SECONDARY:
      return palettes.content.accent;
    case ContentColor.PRIMARY_CONTRAST:
      return palettes.content.neutralContrast;
    case ContentColor.PRIMARY_SUBDUED:
      return palettes.content.neutralSubdued;
    case ContentColor.SUCCESS:
      return palettes.content.positive;
    case ContentColor.PRIMARY:
    default:
      return palettes.content.neutral;
  }
}
