import AppRoutes from './AppRoutes';
import HomePage from './HomePage';
import MarketingRoutes from './MarketingRoutes';
import NotFound from './NotFound';
import getSharedLoader from '../lib/router/getSharedLoader';

import React from 'react';
import {
  Outlet,
  Route,
  ScrollRestoration,
  createRoutesFromElements,
} from 'react-router-dom';

export default createRoutesFromElements(
  <Route
    element={
      <>
        <ScrollRestoration />
        <Outlet />
      </>
    }
  >
    <Route
      element={<Outlet />}
      path="/app/*"
    >
      {AppRoutes}
    </Route>

    {MarketingRoutes}

    <Route
      element={<HomePage />}
      loader={getSharedLoader({
        loadableRoot: HomePage,
      })}
      path="/"
    />
    <Route
      element={<NotFound />}
      loader={getSharedLoader({
        loadableRoot: NotFound,
      })}
      path="*"
    />
  </Route>,
);
