import brand from '../../__generated__/intl/strings/brand';
import setDocumentTitle from '../browser/setDocumentTitle';

import {LoadableComponent} from '@loadable/component';
import {LoaderFunction, defer, redirectDocument} from 'react-router-dom';

export interface LoaderData {
  readonly title: string;
  rootComponent?: React.ComponentType<unknown>;
}

export interface SharedLoaderOptions {
  getTitle?: () => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadableRoot?: LoadableComponent<any>;
}

export type Loader = LoaderFunction<LoaderData>;

/**
 *
 * @param getTitle This is made into a callback instead of a string because we only want this be called
 *                 during a render so that the user's locale can be correctly selected from the request
 *                 context.
 * @returns
 */
export default function getSharedLoader(
  options: SharedLoaderOptions,
): LoaderFunction<LoaderData> {
  return async (args): Promise<LoaderData | Response> => {
    const {request} = args;
    const {loadableRoot} = options;
    const title = options.getTitle?.() ?? brand();
    // Side effects
    setDocumentTitle(title);

    let result: React.ComponentType<unknown> | undefined;

    try {
      result = await loadableRoot?.load();
    } catch (err) {
      return redirectDocument(request.url);
    }

    return defer({
      title,
      rootComponent: result,
    }) as never;
  };
}
